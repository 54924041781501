import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import type { InputProps } from 'components/commercetools-ui/atoms/input';
import PasswordInput from 'components/commercetools-ui/atoms/input-password';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import AccountForm from '../../../account-atoms/account-form';
import useDiscardForm from '../../../hooks/useDiscardForm';
import scrollToError from 'helpers/utils/scrollToError';

type ChangePasswordFormData = {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
};

type FieldErrors = {
    [key in keyof ChangePasswordFormData]?: { message: string };
};

const ChangePasswordForm = () => {
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
    const { formatMessage: formatValidationMessage } = useFormat({ name: 'validation' });

    const { changePassword } = useAccount();
    const { discardForm } = useDiscardForm();
    const [formErrors, setFormErrors] = useState<FieldErrors>();

    const defaultData: ChangePasswordFormData = { oldPassword: '', newPassword: '', confirmPassword: '' };
    const [data, setData] = useState<ChangePasswordFormData>(defaultData);
    const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        setLoading(true);
        // Request update password
        const result = await changePassword(data.oldPassword, data.newPassword, data.confirmPassword);
        setLoading(false);
        if ('errors' in result) {
            setFormErrors(result.errors as FieldErrors);
        } else {
            toast.success(formatAccountMessage({ id: 'data.updated', defaultMessage: 'Data updated successfully.' }));
            discardForm();
        }
    };

    const inputFields: InputProps[] = [
        {
            label: formatAccountMessage({ id: 'password.current', defaultMessage: 'Current password' }),
            name: 'oldPassword',
            ...(formErrors?.oldPassword?.message && {
                errorMessage: formatValidationMessage({
                    id: formErrors.oldPassword.message,
                    defaultMessage: 'Please add your password'
                })
            })
        },
        {
            label: formatAccountMessage({ id: 'password.new', defaultMessage: 'New password' }),
            name: 'newPassword',
            ...(formErrors?.newPassword?.message && {
                errorMessage: formatValidationMessage({
                    id: formErrors.newPassword.message,
                    defaultMessage: 'Please add your password'
                })
            })
        },
        {
            label: formatAccountMessage({ id: 'password.confirm', defaultMessage: 'Confirm password' }),
            name: 'confirmPassword',
            ...(formErrors?.confirmPassword?.message && {
                errorMessage: formatValidationMessage({
                    id: formErrors.confirmPassword.message,
                    defaultMessage: 'Please add your password'
                })
            })
        }
    ];

    useEffect(() => {
        if (formErrors) {
            scrollToError();
        }
    }, [formErrors]);

    return (
        <AccountForm
            title={formatAccountMessage({ id: 'password.change', defaultMessage: 'Change your password' })}
            defaultCTASection
            loading={loading}
            onSubmit={handleSubmit}
            containerClassName="grid gap-12 md:p-8 mt-26"
        >
            <div className="grid gap-12">
                {inputFields.map((fieldProps, index) => (
                    <PasswordInput
                        key={fieldProps.name ?? '' + index}
                        {...fieldProps}
                        onChange={handleChange}
                        value={data[fieldProps.name as keyof ChangePasswordFormData]}
                        required
                    />
                ))}
            </div>
        </AccountForm>
    );
};

export default ChangePasswordForm;
