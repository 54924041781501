import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/navigation';
import Script from 'next/script';
import type { Account } from '@wilm/shared-types/account';
import { PaymentMethodType } from '@wilm/shared-types/cart/Payment';
import toast from 'react-hot-toast';
import Button from 'components/commercetools-ui/atoms/button';
import Dropdown from 'components/commercetools-ui/atoms/dropdown';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import AccountAddresses from 'components/commercetools-ui/organisms/checkout/components/steps/sections/addresses/components/account-addresses';
import type { Address } from 'components/commercetools-ui/organisms/checkout/components/steps/sections/addresses/types';
import { useCheckout } from 'components/commercetools-ui/organisms/checkout/provider';
import { useFormat } from 'helpers/hooks/useFormat';
import { sdk } from 'sdk';
import { useAccount } from 'frontastic';
import useStudioPaymentsSettings from 'frontastic/hooks/useStudioPaymentsSettings';
import Fields from '../../../../checkout/components/steps/sections/payment/components/creditCard/fields';

const PaymentAdd = () => {
    const router = useRouter();
    const { formatMessage: formatPaymentMessage } = useFormat({ name: 'payment' });
    const { mutateAccount } = useAccount();
    const { paymentData, createPaymentToGetTokenContext } = useCheckout();

    const [billingAddress, setBillingAddress] = useState({} as Address);
    const [billingNotSelected, setBillingNotSelected] = useState(false);
    const { billingAddresses } = useAccount();
    const { flexFormLink } = useStudioPaymentsSettings();
    const [submitForm, setSubmitForm] = useState(false);
    const [currency, setCurrency] = useState<string>('GBP');
    const [currencyInvalid, setCurrencyInvalid] = useState<boolean>(false);
    interface Currencies {
        value: string;
        label: string;
    }

    const currencies = useRef<Currencies[]>([
        { label: 'Please select', value: '' },
        { label: 'HKD', value: 'HKD' },
        { label: 'USD', value: 'USD' },
        { label: 'GBP', value: 'GBP' },
        { label: 'EUR', value: 'EUR' },
        { label: 'SGD', value: 'SGD' },
        { label: 'MYR', value: 'MYR' }
    ]);

    const onInvalid = () => {
        if (!billingAddress.addressId) {
            setBillingNotSelected(true);
        }
        setSubmitForm(false);
    };

    useEffect(() => {
        if (currency) {
            console.log('<<<PaymentAdd currency', currency);
            createPaymentToGetTokenContext(onInvalid, true, currency);
        }
    }, [currency]);

    async function onSubmit(token: string) {
        if (paymentData.type !== PaymentMethodType.CARD) return;
        setBillingNotSelected(false);

        if (!billingAddress.addressId) {
            setBillingNotSelected(true);

            setSubmitForm(false);
            return;
        }

        const response = await sdk.callAction({
            actionName: `cart/createToken`,
            payload: {
                token: token || paymentData.token,
                paymentId: paymentData.paymentId,
                addressId: billingAddress?.addressId,
                currencyCode: currency
            }
        });
        const accountResponse = (response.isError ? null : response.data) as Account;
        if (!accountResponse) {
            setSubmitForm(false);
            toast.error(
                `${formatPaymentMessage({
                    id: 'customer.token.creation.failed',
                    defaultMessage: 'Token Creation failed'
                })}`
            );
        } else {
            await mutateAccount();
        }
        router.push('/account?hash=payment');
    }

    return (
        flexFormLink && (
            <>
                <Script src={flexFormLink} strategy="lazyOnload" />
                <div className="ml-0 mt-20 lg:ml-44 lg:mt-40">
                    <div className="mt-24 px-16 md:mt-0 md:px-24 lg:px-0">
                        <Typography as="h2" className="text-primary-black md:text-22 lg:text-24">
                            {formatPaymentMessage({
                                id: 'add.card',
                                defaultMessage: 'Add new card'
                            })}
                        </Typography>
                    </div>
                </div>
                {billingAddresses.length === 0 && (
                    <div className="ml-0 lg:ml-44 ">
                        <div>
                            {' '}
                            {formatPaymentMessage({
                                id: 'store.card',
                                defaultMessage: 'To store a card you need a billing address first'
                            })}
                        </div>
                        <Button variant="primary" size="s" className="mt-20 rounded-md px-16 py-8 xl:mt-12">
                            <Link link={`/account/?hash=addresses&id=address-add`}>
                                <Typography>{formatPaymentMessage({ id: 'address.add', defaultMessage: 'Add new address' })}</Typography>
                            </Link>
                        </Button>
                    </div>
                )}
                {currencies?.current.length > 0 && billingAddresses.length > 0 && (
                    <div className="ml-0 px-16 pt-32 md:max-w-[436px] lg:ml-44 lg:px-0">
                        <div className="col-span-3 mb-16">
                            <Dropdown
                                name="currency"
                                items={currencies.current}
                                required={true}
                                className="w-full"
                                label={formatPaymentMessage({ id: 'stateOrProvince', defaultMessage: 'Currency' })}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                                    setCurrencyInvalid(false);
                                    setCurrency(e.target.value);
                                }}
                                defaultValue="GBP"
                            />
                            {currencyInvalid && (
                                <p className="mt-12 text-left font-body text-sm leading-tight text-input-error">
                                    {formatPaymentMessage({
                                        id: 'card.invalid.currency',
                                        defaultMessage: 'Please select a card currency'
                                    })}
                                </p>
                            )}
                        </div>
                    </div>
                )}
                {billingAddresses.length > 0 && currency && (
                    <>
                        <div className="ml-0 px-16 md:max-w-[436px] lg:ml-44 lg:px-0">
                            <Fields onInvalid={onInvalid} onValid={onSubmit} submitForm={submitForm} currency={currency} />
                        </div>

                        <div className="bg-white px-16 pt-16 lg:px-44 lg:pb-36 lg:pt-16">
                            <div className="mt-20">
                                <h5 className="text-base">
                                    {formatPaymentMessage({ id: 'billingAddress', defaultMessage: 'Billing Address' })}
                                </h5>
                                <AccountAddresses
                                    className="mt-20"
                                    type="billing"
                                    onSelectAddress={address => {
                                        setBillingNotSelected(false);
                                        setBillingAddress(address);
                                    }}
                                    selectedAddress={billingAddress}
                                />
                            </div>
                            {billingNotSelected && (
                                <p className="mt-12 text-left font-body text-sm leading-tight text-input-error">
                                    {formatPaymentMessage({
                                        id: 'billing.invalid',
                                        defaultMessage: 'Please choose billing address'
                                    })}
                                </p>
                            )}
                        </div>

                        <div className="ml-0 mt-20 lg:ml-44 lg:mt-40">
                            <div className="mt-24 px-16 md:mt-0 md:px-24 lg:px-0">
                                <div className="mt-32 flex">
                                    <Button variant="secondary" className="w-112" onClick={() => router.push('/account#payment')}>
                                        <Typography as="h2" className="text-center text-14 text-primary-black">
                                            {formatPaymentMessage({
                                                id: 'cancel',
                                                defaultMessage: 'Cancel'
                                            })}
                                        </Typography>
                                    </Button>

                                    <Button
                                        variant="primary"
                                        className="ml-12 w-112"
                                        loading={submitForm}
                                        type="submit"
                                        onClick={() => {
                                            setSubmitForm(true);
                                        }}
                                    >
                                        {formatPaymentMessage({
                                            id: 'save',
                                            defaultMessage: 'Save'
                                        })}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        )
    );
};

export default PaymentAdd;
