import { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import type { Address } from '@wilm/shared-types/account/Address';
import PhoneInput from 'react-phone-number-input';
import type { Value as PhoneNumberValue, Country as CountryCode } from 'react-phone-number-input';
import Button from 'components/commercetools-ui/atoms/button';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import Dropdown from 'components/commercetools-ui/atoms/dropdown';
import Input from 'components/commercetools-ui/atoms/input';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCountries } from 'providers/countries';
import { useAccount } from 'frontastic';
import DeleteModal from './deleteModal';
import usePropsToAddressType from './mapPropsToAddressType';
import type { States } from '../../../../../../helpers/utils/mapStates';
import mapStates from '../../../../../../helpers/utils/mapStates';
import TrashIcon from '../../../../../icons/trash';
import type { FieldErrors } from '../../../checkout/components/steps/sections/addresses/types';
import AccountForm from '../../account-atoms/account-form';
import SaveOrCancel from '../../account-atoms/save-or-cancel';
import useDiscardForm from '../../hooks/useDiscardForm';
import scrollToError from 'helpers/utils/scrollToError';

export interface AddressFormProps {
    addressId?: string;
    editedAddressId?: Address['addressId'];
}

export interface AddressFormData extends Address {
    isDefaultAddress?: boolean;
    isBillingAddress?: boolean;
    isDefaultBillingAddress?: boolean;
    isDefaultShippingAddress?: boolean;
}

declare global {
    interface Window {
        pca: any;
    }
}

interface LoqateAddress {
    Line1?: string;
    Line2?: string;
    PostalCode?: string;
    City?: string;
    CountryIso2?: string;
    streetName?: string;
    additionalStreetInfo?: string;
    city?: string;
    country?: string;
    Province?: string;
}

type AddressType = 'shipping' | 'billing';
type AddressTypeOptions = { label: string; value: AddressType }[];

const AddressForm: React.FC<AddressFormProps> = ({ editedAddressId }) => {
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
    const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
    const { formatMessage } = useFormat({ name: 'common' });
    const { formatMessage: formatValidationMessage } = useFormat({ name: 'validation' });

    const { countries, countryCodes } = useCountries();
    const defaultStateOption = formatMessage({ id: 'select.state', defaultMessage: 'Please select your state/province' });

    const phoneCountries = countryCodes as CountryCode[];

    const { removeAddress, account } = useAccount();
    const { mapPropsToAddress } = usePropsToAddressType();
    const { discardForm } = useDiscardForm();

    const [loading, setLoading] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    const toggleLoadingOn = () => setLoading(true);
    const toggleLoadingOff = () => setLoading(false);
    const [phoneValue, setPhoneValue] = useState<PhoneNumberValue>();
    const [formErrors, setFormErrors] = useState<FieldErrors>();
    const [states, setStates] = useState<States[]>();
    const [requiredState, setRequiredState] = useState(false);
    const formElement = useRef<HTMLDivElement>(null);
    const [isEventAdded, setIsEventAdded] = useState<boolean>(false);

    //new address data
    const defaultData = useMemo(() => {
        const accountAddress = account?.addresses?.find((address: AddressFormData) => address.addressId === editedAddressId);

        if (accountAddress) {
            accountAddress.addressType = mapPropsToAddress(accountAddress).addressType;
        }

        return accountAddress;
    }, [account?.addresses, editedAddressId, mapPropsToAddress]);

    const [data, setData] = useState(defaultData);
    const [loqateFields, setLoqateFields] = useState<LoqateAddress>();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const addressTypes: AddressTypeOptions = [
        { label: formatCheckoutMessage({ id: 'shippingAddress', defaultMessage: 'Shipping Address' }), value: 'shipping' },
        { label: formatCheckoutMessage({ id: 'billingAddress', defaultMessage: 'Billing Address' }), value: 'billing' }
    ];

    const formTitle = formatAccountMessage(
        editedAddressId ? { id: 'address.edit', defaultMessage: 'Edit address' } : { id: 'address.add', defaultMessage: 'Add an address' }
    );

    useEffect(() => {
        setData(defaultData);
    }, [defaultData]);

    const updateData = (name: string, value: boolean | string) => {
        setData({ ...data, [name]: value });
    };

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const target = e.target;

            if (target.name === 'country') {
                const mapedStates = mapStates(target.value, defaultStateOption);
                setStates(mapedStates.states);
                setRequiredState(mapedStates.requred);
            }

            updateData(target.name, target.value);
        },
        [data]
    );

    const handleDelete = async () => {
        setLoadingDelete(true);
        if (data?.addressId) {
            await removeAddress(data.addressId);
            setLoadingDelete(false);
            closeModal();
            discardForm();
        }
    };

    const onPcaLoad = useCallback(
        (type: any, id: any, control: any) => {
            control.listen('populate', (address: LoqateAddress) => {
                const nextState = {
                    streetName: address.Line1,
                    additionalAddressInfo: address.Line2,
                    postalCode: address.PostalCode,
                    city: address.City,
                    country: address.CountryIso2,
                    region: address.Province
                };

                setLoqateFields(nextState);
            });
        },
        [data]
    );

    useEffect(() => {
        setData({
            ...data,
            ...loqateFields
        });
    }, [loqateFields]);

    useEffect(() => {
        if (window?.pca && typeof window.pca === 'object') {
            if (!isEventAdded) {
                window?.pca.on('load', onPcaLoad);

                setIsEventAdded(true);

                if (typeof window.pca?.load === 'function' && formElement?.current) {
                    window?.pca?.load();
                }

                window?.pca.on('options', (type: any, key: any, options: any) => {
                    options.countries.value = editedAddressId ? defaultData?.country : 'GB';
                });
            }
        }
    }, [formElement.current, isEventAdded, editedAddressId]);
    useEffect(() => {
        if (editedAddressId && data?.country) {
            const mapedStates = mapStates(data?.country, defaultStateOption);
            setStates(mapedStates.states);
            setRequiredState(mapedStates.requred);
        }
    }, [data]);

    const setAddress = (result: AddressFormData) => {
        if ('errors' in result) {
            setLoading(false);
            setFormErrors(result.errors as FieldErrors);
        } else {
            toggleLoadingOff();
            discardForm();
        }
    };

    const handleSubmit = useCallback(
        async (e: React.FormEvent) => {
            e.preventDefault();
            toggleLoadingOn();

            if (!data) return;

            const { addAddress, updateAddress } = mapPropsToAddress(data);

            if (editedAddressId) {
                if (defaultData?.addressType !== data?.addressType) {
                    await removeAddress(defaultData!.addressId!);
                    const result = await addAddress();
                    setAddress(result);
                } else {
                    const result = await updateAddress();
                    setAddress(result);
                }

                return;
            }
            const result = await addAddress();
            setAddress(result);
        },
        [data]
    );

    useEffect(() => {
        if (formErrors) {
            scrollToError();
        }
    }, [formErrors]);

    return (
        <AccountForm onSubmit={handleSubmit} title={formTitle} loading={loading} containerClassName="grid gap-12 md:p-8">
            <div className="mt-18 grid grid-cols-3 gap-12" ref={formElement}>
                <div className="col-span-3">
                    <Input
                        label={formatMessage({ id: 'firstName', defaultMessage: 'First Name' })}
                        required
                        type="text"
                        name="firstName"
                        id="first-name"
                        value={data?.firstName ?? ''}
                        autoComplete="first-name"
                        onChange={handleChange}
                        errorMessage={
                            formErrors?.firstName?.message &&
                            formatValidationMessage({
                                id: formErrors.firstName.message,
                                defaultMessage: 'Please add first name'
                            })
                        }
                    />
                </div>
                <div className="col-span-3">
                    <Input
                        label={formatMessage({ id: 'lastName', defaultMessage: 'Last Name' })}
                        required
                        type="text"
                        name="lastName"
                        id="last-name"
                        value={data?.lastName ?? ''}
                        autoComplete="last-name"
                        onChange={handleChange}
                        errorMessage={
                            formErrors?.lastName?.message &&
                            formatValidationMessage({
                                id: formErrors.lastName.message,
                                defaultMessage: 'Please add last name'
                            })
                        }
                    />
                </div>
                <div className="col-span-3">
                    <Input
                        label={formatMessage({ id: 'address', defaultMessage: 'Address' })}
                        type="text"
                        required
                        name="streetName"
                        id="line1"
                        value={data?.streetName ?? ''}
                        autoComplete="primary-address"
                        onChange={handleChange}
                        errorMessage={
                            formErrors?.streetName?.message &&
                            formatValidationMessage({
                                id: formErrors.streetName.message,
                                defaultMessage: 'Please add your address'
                            })
                        }
                    />
                </div>
                <div className="col-span-3">
                    <Input
                        label={formatMessage({ id: 'address2', defaultMessage: 'Address 2' })}
                        type="text"
                        name="additionalAddressInfo"
                        id="line2"
                        value={data?.additionalAddressInfo ?? ''}
                        autoComplete="additional-address-info"
                        onChange={handleChange}
                        errorMessage={
                            formErrors?.additionalAddressInfo?.message &&
                            formatValidationMessage({
                                id: formErrors.additionalAddressInfo.message,
                                defaultMessage: 'This field must have a maximum 60 or less characters'
                            })
                        }
                    />
                </div>
                <div className="col-span-3">
                    <Input
                        label={formatMessage({ id: 'city', defaultMessage: 'Town / City' })}
                        type="text"
                        required
                        name="city"
                        id="city"
                        value={data?.city ?? ''}
                        onChange={handleChange}
                        errorMessage={
                            formErrors?.city?.message &&
                            formatValidationMessage({
                                id: formErrors.city.message,
                                defaultMessage: 'Please add your town/city'
                            })
                        }
                    />
                </div>
                <div className="col-span-1">
                    <Input
                        label={formatMessage({ id: 'zipCode', defaultMessage: 'Postcode' })}
                        required
                        type="text"
                        name="postalCode"
                        id="postal-code"
                        value={data?.postalCode ?? ''}
                        autoComplete="postal-code"
                        onChange={handleChange}
                        errorMessage={
                            formErrors?.postalCode?.message &&
                            formatValidationMessage({
                                id: formErrors.postalCode.message,
                                defaultMessage: 'This field must have a maximum 10 or less characters'
                            })
                        }
                    />
                </div>
                <div className="col-span-2">
                    <Dropdown
                        name="country"
                        items={countries}
                        required
                        className="w-full"
                        label={formatMessage({ id: 'Country', defaultMessage: 'Country' })}
                        onChange={handleChange}
                        value={data?.country ?? 'GB'}
                    />
                </div>
                {states?.length ? (
                    <div className="col-span-3">
                        <Dropdown
                            name="region"
                            items={states}
                            required={requiredState}
                            className="w-full"
                            label={formatMessage({ id: 'stateOrProvince', defaultMessage: 'State/Province' })}
                            onChange={handleChange}
                            defaultValue={data?.region ?? ''}
                            errorMessage={
                                formErrors?.region?.message &&
                                formatValidationMessage({
                                    id: formErrors.region.message,
                                    defaultMessage: 'Please select your state/province'
                                })
                            }
                        />
                    </div>
                ) : (
                    <></>
                )}
                <div className="col-span-3" data-error={!!formErrors?.phone?.message}>
                    <label htmlFor="phone" className="text-label-text text-left text-14 font-label">
                        {formatMessage({ id: 'phoneNumber', defaultMessage: 'Phone Number *' })}
                    </label>

                    <PhoneInput
                        maxLength={22}
                        name="phone"
                        international
                        countryCallingCodeEditable={false}
                        className={formErrors?.phone?.message && 'error'}
                        defaultCountry="GB"
                        countries={phoneCountries}
                        value={data?.phone ?? phoneValue}
                        required
                        onChange={e => {
                            handleChange?.({
                                target: { name: 'phone', value: e ?? '' }
                            } as any);
                            setPhoneValue(e);
                        }}
                    />
                    {formErrors?.phone?.message && (
                        <Typography className="mt-12 text-sm text-input-error" as="p">
                            {formatValidationMessage({
                                id: formErrors.phone.message,
                                defaultMessage: 'Please add your phone number'
                            })}
                        </Typography>
                    )}
                </div>
                <div className="col-span-3">
                    <Dropdown
                        name="addressType"
                        items={addressTypes}
                        className="w-full"
                        onChange={handleChange}
                        defaultValue={editedAddressId && data ? mapPropsToAddress(data).addressType : addressTypes[0].value}
                        label={formatAccountMessage({
                            id: 'address.type',
                            defaultMessage: 'Address type'
                        })}
                    />
                </div>
                <div className="col-span-3">
                    <Checkbox
                        name="isDefaultAddress"
                        id="is-default-address"
                        checked={data?.isDefaultBillingAddress ?? data?.isDefaultShippingAddress ?? false}
                        onChange={({ name, checked }) => updateData(name, checked)}
                        containerClassName="mt-4 md:mb-10 mb-12"
                        label={formatAccountMessage({
                            id: 'address.setDefault',
                            defaultMessage: 'Save as default address'
                        })}
                    />
                </div>
            </div>

            <div className="mt-6 grid h-fit items-center gap-20 md:flex">
                {editedAddressId && (
                    <Button
                        variant="ghost"
                        size="fit"
                        className="flex items-center gap-8 hover:cursor-pointer hover:opacity-70"
                        onClick={e => {
                            e.preventDefault();
                            setModalIsOpen(true);
                        }}
                    >
                        <TrashIcon className="size-20 text-secondary-black" />{' '}
                        <span className="text-14 leading-[114%] text-secondary-black">
                            {formatMessage({ id: 'delete', defaultMessage: 'Delete' })}{' '}
                        </span>
                    </Button>
                )}

                <SaveOrCancel onCancel={discardForm} loading={loading} />
            </div>

            <DeleteModal modalIsOpen={modalIsOpen} loading={loadingDelete} closeModal={closeModal} handleDelete={handleDelete} />
        </AccountForm>
    );
};

export default AddressForm;
