import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import type { Address as AddressType } from '@wilm/shared-types/account';
import Button from 'components/commercetools-ui/atoms/button';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import Address from './address';
import type { AddressFormData } from './address-form';
import usePropsToAddressType from './mapPropsToAddressType';

const Addresses = () => {
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

    const { mapPropsToAddress } = usePropsToAddressType();

    const { account, defaultBillingAddress, defaultShippingAddress } = useAccount();
    const addresses = account?.addresses?.slice().reverse();

    const router = useRouter();

    const [selectedBillingAddress, setSelectedBillingAddress] = useState<AddressType>();
    const [selectedShippingAddress, setSelectedShippingAddress] = useState<AddressType>();

    const selectAddress = (address: AddressFormData) => {
        address.isBillingAddress ? setSelectedBillingAddress(address) : setSelectedShippingAddress(address);
    };

    const handleClick = useCallback(() => {
        router.push('?hash=addresses&id=address-add');
    }, []);

    const setAddressAsDefault = useCallback(
        async (address: AddressType) => {
            const { setAsDefault } = mapPropsToAddress(address as AddressFormData);
            await setAsDefault();
        },
        [mapPropsToAddress]
    );

    useEffect(() => {
        if (selectedBillingAddress !== defaultBillingAddress?.addressId) {
            void setAddressAsDefault(selectedBillingAddress!);
        }
    }, [defaultBillingAddress?.addressId, selectedBillingAddress, setAddressAsDefault]);

    useEffect(() => {
        if (selectedShippingAddress !== defaultShippingAddress?.addressId) {
            void setAddressAsDefault(selectedShippingAddress!);
        }
    }, [defaultShippingAddress?.addressId, selectedShippingAddress, setAddressAsDefault]);

    useEffect(() => {
        if (!selectedBillingAddress) {
            setSelectedBillingAddress(defaultBillingAddress);
        }

        if (!selectedShippingAddress) {
            setSelectedShippingAddress(defaultShippingAddress);
        }
    }, [defaultBillingAddress, defaultShippingAddress, selectedBillingAddress, selectedShippingAddress]);

    return (
        <div className="mt-20 px-16 md:px-24 lg:mt-40 lg:px-44">
            <div className="hidden pb-12 md:block  md:pb-24">
                <Typography as="h2" className="text-22 text-primary-black">
                    {formatAccountMessage({
                        id: 'addresses',
                        defaultMessage: 'Addresses'
                    })}
                </Typography>
            </div>
            <div>
                <Typography className="mb-28 text-md leading-loose text-secondary-black md:mb-30 md:text-base ">
                    {formatAccountMessage({
                        id: 'address.desc',
                        defaultMessage: 'Manage or add addresses for your account.'
                    })}
                </Typography>
                <Button variant="primary" onClick={handleClick}>
                    {formatAccountMessage({ id: 'address.add', defaultMessage: 'Add an address' })}
                </Button>
            </div>

            <form className="mt-24 grid gap-15 pb-28 md:mt-28 md:gap-20 2xl:mt-32">
                {addresses?.map(address => (
                    <Address
                        key={address.addressId}
                        address={address}
                        isChecked={false}
                        selectAddress={selectAddress}
                        isDefaultAddress={
                            address.addressId == selectedBillingAddress?.addressId ||
                            address.addressId == selectedShippingAddress?.addressId
                        }
                    />
                ))}
            </form>
        </div>
    );
};

export default Addresses;
